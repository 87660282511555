<template>
  <div>
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah talent <template v-if="currentStep !== 1"> - Kuisioner</template>
    </div>
    <template>
        <Bio v-show="currentStep === 1" @submitted="toQuiz" :buttonBackActive="buttonBackActive" />
    </template>
    <template>
      <BCard>
        <Quiz v-show="currentStep === 2" :idTalent="idTalent" @back="handleBackFromQuiz()"/>
      </BCard>
    </template>
  </div>
</template>

<script>
import { required } from '@validations'
import isEmpty from 'lodash/isEmpty'
import Bio from './Bio.vue'
import Quiz from './Quistioner.vue'

export default {
  components: { Bio, Quiz },
  data() {
    return {
      loading: false,
      loadingUpdate: false,
      error: '',
      name: '',
      icon: null,
      iconInitial: null,
      isEmpty,
      disabled: false,
      currentStep: 1,
      idTalent: 0,
      buttonBackActive: false,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  methods: {
    toQuiz(params) {
      this.idTalent = params
      this.currentStep = 2
      this.buttonBackActive = false
    },
    handleBackFromQuiz() {
      this.currentStep = 1
      this.buttonBackActive = true
    },
  },
}
</script>
